/*ReactJS*/
import React, { useState, useContext } from 'react';

/*Context*/
import { ServiceContext } from 'context/ServiceContext';
import { useUserState } from 'context/UserContext';

/*Packages*/
import { useForm } from 'react-hook-form';
import moment from 'moment';

/*Service*/
import {
  CreateNoticeRequest,
  DeactivateNoticeRequest,
} from 'proto/irspb/notice_grpc_web_pb';

/*Material UI Components*/
import {
  Typography,
  Button,
  Modal,
  Backdrop,
  Fade,
  Box,
  TextField,
  MenuItem,
  InputLabel,
  Select,
} from '@material-ui/core';

/*Toast Notification*/
import {
  notifyError,
  notifySuccess,
} from 'components/Notification/Notification';

/*Material UI Icons*/
import { Add as AddIcon, Remove as RemoveIcon } from '@material-ui/icons';

/*Material Ui Confirm*/
import { useConfirm } from 'material-ui-confirm';

/*Material UI Table*/
import MUIDataTable from 'mui-datatables';

/*Utils*/
import { formatPbDate, formatSeconds } from 'lib/fmt';

/*Styles*/
import tableTheme from '../../../components/Table/TableStyle';
import { MuiThemeProvider } from '@material-ui/core/styles';
import useStyles from '../../../styles';

/*Google Date*/
const google_date = require('../../../google/type/date_pb.js');

function RenderComponent() {
  const [, setValue] = useState(0);
  return () => setValue((value) => ++value);
}

export default function NoticeTable({ accountNo, noticeList }) {
  const rows = noticeList;
  const classes = useStyles();
  const user = useUserState();
  const confirm = useConfirm();
  const reRender = RenderComponent(); //Re-renders component when something change in component state
  const { noticeServiceClient: notice } = useContext(ServiceContext);

  const [selected, setSelected] = React.useState([]);
  const [open, setOpen] = useState(false);
  const userName = user?.googleAccount?.profileObj?.name || 'User';

  const { register, handleSubmit, getValues, reset } = useForm({
    defaultValues: {
      start_date: moment().format('yyyy-MM-DD'),
      end_date: moment().format('yyyy-MM-DD'),
      created_by: userName,
    },
  });

  const addNotice = (data) => {
    let addReq = new CreateNoticeRequest();
    addReq
      .setAccountNo(accountNo)
      .setType(data.type)
      .setSubtype(data.type === 'b_notice' ? data.subtype : '')
      .setCreatedBy(data.created_by);

    if (data.start_date) {
      let gglStartDate = new google_date.Date();
      let [y, m, d] = data.start_date.split('-');
      gglStartDate
        .setYear(y)
        .setMonth(m)
        .setDay(d);
      addReq.setStartDate(gglStartDate);
    }

    notice.createNotice(addReq, {}, (err, res) => {
      if (err) {
        console.error(err);
        notifyError(err.message);
        return;
      }
      let obj = res.toObject().notice;
      let newNotice = {
        ...obj,
        startDate: formatPbDate(obj.startDate),
        endDate: formatPbDate(obj.endDate),
        createdAt: formatSeconds(obj.createdAt.seconds),
        updatedAt: formatSeconds(obj.updatedAt.seconds),
      };
      rows.push(newNotice);
      reRender();
      notifySuccess('New notice has been added.');
      setOpen(false);
      reset();
    });
  };

  const deactivateNotice = () => {
    if (!selected.length) {
      return;
    }
    confirm({
      description: (
        <>
          <div className={classes.grdRow}>
            <div className={classes.grdCell1}>
              <TextField
                fullWidth
                label="End Date"
                type="date"
                className={classes.textField}
                {...register('end_date')}
                InputLabelProps={{ shrink: true }}
              />
            </div>
          </div>
          <Typography>
            You are about to deactivate {selected.length} Notice(s) in your
            system. Please confirm your action.
          </Typography>
        </>
      ),
      confirmationText: 'Yes, Deactivate',
    })
      .then(() => {
        selected.forEach((index) => {
          const endDate = getValues('end_date');
          let n = rows[index];
          let deactivateReq = new DeactivateNoticeRequest();
          deactivateReq.setId(n.id);

          if (moment(n.startDate) > moment(endDate)) {
            notifyError('End Date must be after Start Date');
            return;
          }

          let gglEndDate = new google_date.Date();
          let [y, m, d] = endDate.split('-');
          gglEndDate
            .setYear(y)
            .setMonth(m)
            .setDay(d);
          deactivateReq.setEndDate(gglEndDate);

          notice.deactivateNotice(deactivateReq, {}, (err) => {
            if (err) {
              console.error(err.message);
              notifyError(err.message);
              return;
            }
            rows[index].status = 'inactive';
            rows[index].endDate = moment(endDate).format('MM/DD/yyyy');
            reRender();
          });
        });
        notifySuccess(`Deactivated Notice(s).`);
        setSelected([]);
        reset();
      })
      .catch((err) => {
        console.error(err);
        return;
      });
  };

  const columns = [
    {
      id: '1',
      name: 'type',
      label: 'Type',
      visibleState: useState(true),
    },
    {
      id: '2',
      name: 'subtype',
      label: 'Subtype',
      visibleState: useState(true),
    },
    {
      id: '3',
      name: 'status',
      label: 'Status',
      visibleState: useState(true),
    },
    {
      id: '4',
      name: 'startDate',
      label: 'Start Date',
      visibleState: useState(true),
    },
    {
      id: '5',
      name: 'endDate',
      label: 'End Date',
      visibleState: useState(true),
    },
    {
      id: '6',
      name: 'createdAt',
      label: 'Created At',
      visibleState: useState(true),
    },
    {
      id: '7',
      name: 'updatedAt',
      label: 'Updated At',
      visibleState: useState(true),
    },
    {
      id: '8',
      name: 'createdBy',
      label: 'Created By',
      visibleState: useState(true),
    },
  ];

  const options = {
    filterType: 'select',
    download: false,
    filter: true,
    search: false,
    print: false,
    sort: true,
    viewColumns: true,
    deselectOnClickAway: true,
    rowsSelected: selected,
    onRowSelectionChange: (rowsSelectedData, allRows, rowsSelected) => {
      setSelected(rowsSelected);
    },
  };

  return (
    <div className={classes.root}>
      <div className={classes.modalActionContainer}>
        <div className={classes.grdRow}>
          <div className={classes.grdCellNone} style={{ marginRight: 10 }}>
            <Button
              variant="contained"
              color="secondary"
              size="large"
              onClick={deactivateNotice}
              startIcon={<RemoveIcon />}
            >
              Deactivate
            </Button>
          </div>
          <div className={classes.grdCellNone}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              startIcon={<AddIcon />}
              onClick={() => setOpen(true)}
            >
              Add New
            </Button>
          </div>
        </div>
      </div>
      <Box>
        <MuiThemeProvider theme={tableTheme()}>
          <MUIDataTable
            title={'Tax Withholding Notice'}
            data={rows}
            columns={columns}
            options={options}
          />
        </MuiThemeProvider>
      </Box>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modalBackdrop}
        open={open}
        onClose={() => setOpen(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.fadeModalMd}>
            <Typography
              id="transition-modal-title"
              variant="h4"
              className={classes.textBold}
              gutterBottom
            >
              Add New Notice
            </Typography>
            <Box mt={5}>
              <form autoComplete="off" onSubmit={handleSubmit(addNotice)}>
                <div className={classes.grdRow}>
                  <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                    <InputLabel id="type" shrink>
                      Type*
                    </InputLabel>

                    <Select {...register('type')} fullWidth required>
                      <MenuItem value="b_notice">B-Notice</MenuItem>
                      <MenuItem value="mandatory_withholding">
                        Mandatory Withholding
                      </MenuItem>
                      <MenuItem value="w8ben_expired">Expired W-8BEN</MenuItem>
                    </Select>
                  </div>
                  <div className={classes.grdCell1}>
                    <InputLabel id="subtype" shrink>
                      Subtype
                    </InputLabel>
                    <Select {...register('subtype')} fullWidth>
                      <MenuItem value="">
                        <em>Blank</em>
                      </MenuItem>
                      <MenuItem value="b1">B1</MenuItem>
                      <MenuItem value="b2">B2</MenuItem>
                    </Select>
                  </div>
                </div>
                <div className={classes.grdRow}>
                  <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                    <TextField
                      fullWidth
                      label="Start Date"
                      type="date"
                      className={classes.textField}
                      {...register('start_date', { required: true })}
                      InputLabelProps={{ shrink: true }}
                    />
                  </div>
                  <div className={classes.grdCell1}>
                    <TextField
                      fullWidth
                      label="Created By"
                      disabled
                      className={classes.textField}
                      {...register('created_by')}
                      InputLabelProps={{ shrink: true }}
                    />
                  </div>
                </div>
                <div className={classes.modalFooter}>
                  <div
                    className={classes.grdCellNone}
                    style={{ marginRight: 10 }}
                  >
                    <Button
                      variant="contained"
                      color="secondary"
                      size="large"
                      onClick={() => setOpen(false)}
                    >
                      Close
                    </Button>
                  </div>
                  <div className={classes.grdCellNone}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      type="submit"
                    >
                      Save
                    </Button>
                  </div>
                </div>
              </form>
            </Box>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
